<template>
  <div class="home">
    <div>
      <div class="img"></div>
      <div class="icoBox">
        <div v-for="(item,index) in topList" :key="index" class="icos" @click=routeTo(item.name)>
          <div :class="item.id == 2?'ico green':'ico'">
            <img :src="item.ico" alt="">
          </div>
          <p>{{item.name}}</p>
        </div>
      </div>

    </div>
    <div class="contain">
      <div class="c-title">
        <div class="line"></div>
        <div class="c-t">销售简报</div>
      </div>
      <div class="cBox">
        <div class="c-item ">
          <img src="../static/CRM/tuceng1.png" alt="" class="imgs">
          <div class="wline"></div>
          <div class="c-txt">
            <p class="t1">{{customer_week}}个</p>
            <p class="t2">本周新增客户</p>
          </div>
        </div>
        <div class="c-item c2">
          <img src="../static/CRM/kehuqunguanli.png" alt="" class="imgs">
          <div class="wline"></div>
          <div class="c-txt">
            <p class="t1">{{customer_month}}个</p>
            <p class="t2">本月新增客户</p>
          </div>
        </div>
        <div class="c-item c3">
          <img src="../static/CRM/goutong55.png" alt="" class="imgs">
          <div class="wline"></div>
          <div class="c-txt">
            <p class="t1">{{log_count_week}}个</p>
            <p class="t2">本周新增沟通</p>
          </div>
        </div>
        <div class="c-item c4">
          <img src="../static/CRM/goutong.png" alt="" class="imgs">
          <div class="wline"></div>
          <div class="c-txt">
            <p class="t1">{{log_count_month}}个</p>
            <p class="t2">本月新增沟通</p>
          </div>
        </div>
<!--        <div class="c-item c5">
          <img src="../static/CRM/rizhi111.png" alt="" class="imgs">
          <div class="wline"></div>
          <div class="c-txt">
            <p class="t1">1个</p>
            <p class="t2">本周新增客户</p>
          </div>
        </div>
        <div class="c-item c6">
          <img src="../static/CRM/rizhi44.png" alt="" class="imgs">
          <div class="wline"></div>
          <div class="c-txt">
            <p class="t1">1个</p>
            <p class="t2">本周新增客户</p>
          </div>
        </div> -->
      </div>
    </div>
    <div class="contain">
      <div class="c-title">
        <div class="line"></div>
        <div class="c-t">余额：{{yufufei}}元</div>
      </div>
    </div>
  </div>
</template>

<script>
import kehu from "../static/CRM/tuceng1.png";
import kehuchi from "../static/CRM/kehuqunguanli.png";
import bohao from "../static/CRM/tuceng2.png";
import qita from "../static/CRM/qita.png";

import global from '../App.vue'
export default {
  data() {
	  console.log(global.token);
	  console.log(global.session_id);
	  console.log(global.name);
    return {
      yufufei: 0,
      active: 0,
      customer_week: 0,
      customer_month: 0,
      log_count_week: 0,
      log_count_month: 0,
	  name:global.token,
	  token:global.name,
	  session_id:global.session_id,
      // business_week: 0,
      // business_month: 0,
      topList: [
        {
          ico: kehu,
          name: "客户",
          id: 0,
        },
        // {
        //   ico: kehuchi,
        //   name: "客户池",
        //   id: 1,
        // },
        {
          ico: bohao,
          name: "拨号",
          id: 2,
        },
        {
          ico: qita,
          name: "话单",
          id: 3,
        },
      ],
    };
  },created () {
    this.getHistoryData()
  },methods: {
	  routeTo(name) {
			console.log(name);
	  //   直接调用$router.push 实现携带参数的跳转
	  if(name == '客户')
	  {
		  
		  this.$router.push({
		    path: `/kehu`,
		  })
	  }
	  if(name == '拨号')
	  {
	  		  
	  		  this.$router.push({
	  		    path: `/bohao`,
	  		  })
	  }
	  
	  if(name == '话单')
	  {
	  		  
	  		  this.$router.push({
	  		    path: `/qita`,
	  		  })
	  }
	  },
    getHistoryData(){
		let param = new URLSearchParams();
		
		 param.append("token", this.$store.getters. getDemoValue);
		
		this.axios.post('/vue.php?m=index&a=index',param).then(res => {
			if (res.data.status === -1) {
				// uni.setStorageSync('name',res.data.name);
				// uni.setStorageSync('session_id',res.data.session_id);
				// uni.setStorageSync('token',res.data.token);
				// global.name = res.data.name;
				// global.session_id = res.data.session_id;
				// global.token = res.data.token;
				// Message.info(res.data);
				setTimeout(()=>{
					this.$router.push({
					  path:"/"
					})
				},1000)
			}
			if (res.data.status === 1) {
				
				console.log(res.data.data);
				this.customer_week = res.data.data.customer_count.week;
				this.customer_month = res.data.data.customer_count.month; 
				this.log_count_week = res.data.data.log_count.week; 
				this.log_count_month = res.data.data.log_count.month; 
				this.yufufei = res.data.data.user_info.yufufei; 
				
				// uni.setStorageSync('name',res.data.name);
				// uni.setStorageSync('session_id',res.data.session_id);
				// uni.setStorageSync('token',res.data.token);
				global.name = this.name;
				global.session_id = this.session_id;
				global.token = this.token;
				// Message.info(res.data);
				// setTimeout(()=>{
				// 	this.$router.push({
				// 	  path:"/"
				// 	})
				// },1000)
			}
			console.log(res);
		}).catch(function (error){
			// Message.info('hello');
			console.log(error);
		});
		
      // this.$router.push({
      //   path:"/crm"
      // })
    }
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #f2f2f2;
  height: 100vh;
}
.img {
  background: url("../static/CRM/tucehng1.png") no-repeat;
  width: 100%;
  height: 206px;
  background-size: cover;
  position: relative;
}
.icoBox {
  position: absolute;
  background: #ffffff;
  display: flex;
  top: 120px;
  left: 13px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 15px;
  width: 352px;
  justify-content: space-around;
  .icos {
    width: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 90px;
    .ico {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: #3584f5;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        display: block;
        height: 25px;
        width: 25px;
      }
      p {
        width: 100%;
      }
    }
    .green {
      background: #4bc64c;
    }
  }
}
.line {
  width: 5px;
  height: 21px;
  background: #5296f7;
}
.contain {
  margin-top: 65px;
  .c-title {
    width: 80%;
    display: flex;
    margin-left: 15px;
  }
  .c-t {
    line-height: 21px;
    margin-left: 7px;
    color: #444444;
    font-size: 18px;
  }
  .cBox {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
    justify-content: space-around;
    margin-top: 25px;
    .c-item {
      margin: 5px 0;
      width: 156px;
      height: 79px;
      background: #ef5e65;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .c2 {
      background: #60a3f8;
    }
    .c3 {
      background: #eca756;
    }
    .c4 {
      background: #7cd364;
    }
    .c5 {
      background: #47c9ca;
    }
    .c6 {
      background: #de91cf;
    }
  }
}
.c-txt {
  height: 40px;
  color: #ffffff;
  .t1 {
    margin: 0;
    font-size: 16px;
  }
  .t2 {
    // margin:0;

    font-size: 12px;
  }
}
.imgs {
  display: block;
  height: 25px;
  width: 25px;
}
.wline {
  width: 1px;
  height: 46px;
  background: #ffffff;
}
</style>